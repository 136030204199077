import React from 'react'

import './App.css'

const App = () => {
  return (
    <div className="apps">
      <header className="header">
        <div className="headerInner">
          <h1 className="headerHeading">
            <span className="headerHeadingTitle">ばすんちゅアプリ</span>
            <span className="headerHeadingDescription">
              〜沖縄のバスを乗りこなそう〜
            </span>
          </h1>
        </div>
      </header>
      <div className="pageContents">
        <div className="message">
          <p>ばすんちゅアプリの旧バージョンは公開終了いたしました！</p>
          <p>
            新バージョンはのんびり開発中ですので、気長にお待ちください。情報が古い
            <a
              href="https://old2019.busnchu.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              旧バージョン
            </a>
            も参照できますが、実際の交通情報としてはご利用いただけません。
          </p>
        </div>
      </div>
    </div>
  )
}

export default App
